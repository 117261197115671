import {$} from './env';

function setup () {
  const filter = $('.search-filter');

  if (!filter.length) {
    // nothing to do
    return;
  }

  const form = filter.find('form');
  form.on('input', 'input[type="checkbox"]', e => {
    form.submit();
  });
}

setup();
