import {$, rootPath} from './env';
import gmQueue from './gmap';
import gmStyle from './gmap.json';

/**
 * checks if the given element is a link or contained by a link
 * 
 * @param {Element} elem 
 */
function isLink (elem) {
  do {
    if (elem.nodeName === 'A') {
      return true;
    }
    elem = elem.parentNode;
  } while (elem && elem.nodeName != 'DIV');
  return false;
}

/**
 * renders the contact 
 * 
 */
function render (elem) {
  /* global google */
  const {LatLng, StyledMapType, Map} = google.maps;
  const {MapTypeId, ControlPosition} = google.maps;
  const {Marker, Animation, Size, Point} = google.maps;

  let zIndex = {
    stc: 10000,
    poi: 0
  };

  const markers = [];
  const center = new LatLng(51.249406, 10.884205);
  const styled = new StyledMapType(gmStyle, {
    name: 'contact-map'
  });

  const initial = { zoom: 7, center };
  const state = Object.assign({}, initial);

  const map = new Map(elem, Object.assign({
    draggable: true,
    scrollwheel: false,
    mapTypeControl: false,
    naviagionControl: false,
    streetViewControl: false,
    mapTypeId: MapTypeId.ROADMAP,
    zoomControlOptions: {
      position: ControlPosition.RIGHT_BOTTOM
    }
  }, initial));
  
  fetch(rootPath + '/kontakte/markers.json')
    .then(response => response.json())
    .then(entries => {
      const event = google.maps.event;
      const length = entries.length;
      for (let index = 0; index < length; ++index) {
        const entry = entries[index];
        const type = entry.type === 'STC' ? 'stc' : 'poi';
        const center = new LatLng(entry.lat, entry.lng);
        const marker = new Marker({
          map,
          title: entry.title,
          position: center,
          clickable: true,
          icon: {
            url: rootPath + `/static/img/marker-${type}.png`,
            size: new Size(165, 143),
            origin: new Point(0, 0),
            anchor: new Point(0, 48),
            scaledSize: new Size(55, 48),
            labelOrigin: new Point(15, 25)
          },
          animation: Animation.DROP,
          zIndex: zIndex[type]++
        });
        markers.push({ marker, entry });
        event.addListener(marker, 'click', () => {
          handle(entry, center);
        });
      }

      event.addDomListener(window, 'resize', () => {
        map.setCenter(state.center);
      });

      map.mapTypes.set('map_style', styled);
      map.setMapTypeId('map_style');

      elem.classList.remove('defer');
      elem.classList.add('ready');

      $('.contact .entry').on('click', function (e) {
        if (isLink(e.target)) {
          return true;
        }

        const elem = $(this);
        const id = elem.attr('id').slice(3) | 0;

        const count = entries.length;
        for (let index = 0; index < count; ++index) {
          const entry = entries[index];
          if (entry.id === id) {
            const center = new LatLng(entry.lat, entry.lng);
            handle(entry, center);
            break;
          }
        }
      });
    });

  /**
   * handles a entry "click"
   * 
   * @param {String} id 
   * @param {LatLng} center 
   */
  function handle (entry, center) {
    // find the entry in the contact list
    const elem = $('#ce-' + entry.id);

    if (!elem.length) {
      // not found?! abort here, because resetting the map
      // without a entry (and back-button) is no fun
      return;
    }

    map.setCenter(center);
    map.setZoom(15);

    $('.contact .entry').hide();
    elem.show();

    const menu = $('.contact .menu');
    const prev = menu.find('.prev');
    const label = menu.find('.label');

    prev.off('click').on('click', e => {
      e.preventDefault();
      $('.contact .entry').show();

      map.setCenter(initial.center);
      map.setZoom(initial.zoom);

      prev.hide();
      label.text(label.data('label'));

      state.zoom = initial.zoom;
      state.center = initial.center;
    });

    prev.show();
    label.text(entry.title);

    state.zoom = 15;
    state.center = center;
  }
}

/**
 * setup contact 
 * 
 */
function setup () {
  const contact = $('.contact .gmap');
  contact.each(function () {
    gmQueue.add(() => render(this));
  });
}

setup();
