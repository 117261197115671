import {$} from './env';

const mmSmall = matchMedia('(max-width:1023px)');
const mmLarge = matchMedia('(min-width:1024px)');
const mmExtra = matchMedia('(min-width:1900px)');

/**
 * creates a svg xml node wrapped in jquery
 *
 * @param {String} name
 * @param {Object} attrs
 * @return {Element}
 */
function make (name, attrs = null) {
  const node = $(document.createElementNS(
    'http://www.w3.org/2000/svg', 
    name
  ));
  
  if (attrs) {
    return node.attr(attrs);
  }

  return node;
}

/**
 * connects two unions
 * 
 * @param {Element} a 
 * @param {Element} b 
 */
function connect (a, b) {
  const aWrap = $(a);
  const bWrap = $(b);

  // remove old wires
  aWrap.find('.wire.bot').remove();
  bWrap.find('.wire.top').remove();

  const aWire = make('svg').addClass('wire bot');
  const bWire = make('svg').addClass('wire top');

  const aList = $('.list', a);
  const bList = $('.list', b);

  const joinT = $('ul, ol', bList).prop('nodeName');

  if (mmSmall.matches) {
    wireBotS();
    wireTopS();
  } else {
    wireBotL();
    wireTopL();
  }

  aWrap.append(aWire);
  bWrap.append(bWire);

  /**
   * creates a wire for the bottom
   * portion of the connection
   * 
   * small viewport
   */
  function wireBotS () {
    const height = (aWrap.outerHeight() - (
      aList.offset().top - 
      aWrap.offset().top + 
      aList.height() - 20
    )) | 0;
    
    // line: right, down
    aWire.attr('viewBox', '0 0 30 ' + height);
    aWire.attr('width', 30);
    aWire.attr('height', height);
    aWire.append(make('rect', { 
      x: 28, y: 0, 
      width: 2, 
      height 
    }));
    aWire.append(make('rect', { 
      x: 0, y: 0, 
      width: 30, 
      height: 2
    }));
  }

  /**
   * creates a wire for the top
   * portion of the connection
   * 
   * small viewport
   */
  function wireTopS () {
    const height = (bList.height() / 2 + (
      bList.offset().top - 
      bWrap.offset().top
    )) + 10 | 0;

    // line: down
    bWire.attr('viewBox', '0 0 30 ' + height);
    bWire.attr('width', 30);
    bWire.attr('height', height);
    bWire.append(make('rect', { 
      x: 28, y: 0, 
      width: 2, 
      height 
    }));

    // if (joinT === 'UL') {
      // line: left
      const single = bList.find('li').length == 1;
      bWire.append(make('rect', { 
        x: single ? 0 : 20, 
        y: height - 2, 
        width: single ? 28 : 8, 
        height: 2 
      }));
    // }
  }

  /**
   * creates a wire for the bottom
   * portion of the connection
   * 
   * large viewport
   */
  function wireBotL () {
    const center = aList.find('.name:first').width() / 2;
    const offset = parseInt(aWrap.css('padding-bottom'));
    const width = center + 50 | 0;
    const height = offset + 5;

    aWire.attr('viewBox', `0 0 ${width} ${height}`);
    aWire.attr('width', width);
    aWire.attr('height', height);

    // line: down
    if (joinT === 'OL') {
      aWire.append(make('rect', {
        x: width - 2, y: 0,
        width: 2,
        height
      }));
      // we're done here
      return;
    }

    // line: down, left, down
    aWire.append(make('rect', {
      x: width - 2, y: 0,
      width: 2,
      height: height / 2
    }));
    aWire.append(make('rect', {
      x: 0, y: height / 2,
      width,
      height: 2
    }));
    aWire.append(make('rect', {
      x: 0, y: height / 2,
      width: 2,
      height: height / 2
    }));
  }

  /**
   * creates a wire for the top
   * portion of the connection
   * 
   * large viewport
   */
  function wireTopL () {
    const center = aList.find('.name:first').width() / 2;
    const width = center + 50 | 0;
    const offset =  parseInt(bWrap.css('padding-top'));

    // line: down
    if (joinT === 'OL') {
      const height = offset + 5;

      bWire.attr('viewBox', `0 0 ${width} ${height}`);
      bWire.attr('width', width);
      bWire.attr('height', height);
      bWire.append(make('rect', {
        x: width - 2, y: 0,
        width: 2,
        height
      }));
      // we're done here
      return;
    }

    // line: down, right
    const height = offset + 5 + bList.height() / 2;
    
    bWire.attr('viewBox', `0 0 ${width} ${height}`);
    bWire.attr('width', width);
    bWire.attr('height', height);
    bWire.append(make('rect', {
      x: 0, y: 0,
      width: 2,
      height
    }));
    bWire.append(make('rect', {
      x: 0, y: height - 2,
      width: bList.find('li').length == 1 ? 50 : 25,
      height: 2
    }));
  }
}

/**
 * render connections
 * 
 */
function render () {
  const courses = $('.course');
  courses.each(function () {
    const unions = $('.union', this);
    const count = unions.length - 1;

    if (count <= 0) {
      return;
    }

    for (let i = 0; i < count; ++i) {
      const a = unions.get(i);
      const b = unions.get(i + 1);
      connect(a, b);
    }
  });
}

/**
 * set everything up
 * 
 */
function setup () {
  render();
  mmSmall.addListener(render);
  mmLarge.addListener(render);
  mmExtra.addListener(render);
}

setup();
