import {$} from './env';

function setup () {
  const news = $('.hero .news');
  if (!news.length) {
    // nothing to do
    return;
  }
  news.slick({
    dots: true
  });
}

setup();
