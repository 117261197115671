
/**
 * checks if required apis are available.
 * ... currently only msie fails this test
 * 
 */
function check () {
  if (typeof window.fetch === 'undefined' ||
      typeof Promise === 'undefined') {

    if (typeof localStorage !== 'undefined') {
      if (localStorage.getItem('msie_warning')) {
        // just abort
        return;
      }

      localStorage.setItem('msie_warning', true);
    }

    // abort the runtime
    const error = [
      'Ihr Browser wird nicht mehr unterstützt!\n',
      'Diese Webanwendung nutzt Technologien, die von Ihrem Browser ' +
        'nicht bereitgestellt werden.',
      'Einige Elemente auf dieser Seite wurden daher deaktiviert.\n', 
      /.*Trident.*/i.test(navigator.userAgent) 
        ? 'Versuchen Sie es mit Microsoft Edge erneut.\n'
        : 'Versuchen Sie ihren Browser zu aktualisieren.\n',
      'Bitte wenden Sie sich bei Problemen an den IT-Verantwortlichen.',
    ].join('\n');

    alert(error);
  }
}

check();
