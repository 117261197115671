import {$} from './env';

function setup () {
  const outlines = $('.outline');

  if (!outlines.length) {
    // nothing to do
    return;
  }

  outlines.each(function () {
    $('.intro', this).each(function () {
      const intro = $(this);
      const toggle = intro.find('.btn.toggle');
      const extra = intro.next('.extra');
        
      if (!extra.length) {
        // no extra content
        return;
      }

      toggle.on('click', e => {
        e.preventDefault();
        const state = toggle.hasClass('is-open');
        if (state) {
          toggle.removeClass('is-open');
          extra.hide();
        } else {
          toggle.addClass('is-open');
          extra.show();
        }
      });
    })
  })
}

setup();
