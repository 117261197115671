import {$} from './env';

const root = $(document.documentElement);

// setup menu-btn
const menuBtn = $('.menu-btn');
menuBtn.on('click', e => {
  e.preventDefault();
  root.toggleClass('menu-open');
});

// handle clicks inside the menu
const menus = $('.site-head .menu');
menus.on('click', 'a[href*="#"]', e => {
  e.preventDefault();
  const self = $(e.target);
  const menu = self.next('ul');
  menu.toggleClass('open');
});

// handle find-btn
const findBtn = $('.find-btn');
findBtn.on('click', e => {
  e.preventDefault();
  root.toggleClass('find-open');
});

const cancelBtn = $('.site-head .search .cancel');
cancelBtn.on('click', e => {
  e.preventDefault();
  root.removeClass('find-open');
});
