let gmReady = false;
let gmItems = [];

const gmQueue = {
  /**
   * adds an entry to the queue
   * 
   * @param {Function} func 
   */
  add (func) {
    if (!gmReady) {
      gmItems.push(func);
      return;     
    }
    func();
  },

  /**
   * runs all items in the queue
   * 
   */
  run () {
    gmReady = true;
    gmItems.forEach(func => {
      func();
    });
    // clear callbacks
    gmItems.length = 0;
  }
};

/**
 * global callback for googleapi
 * 
 */
window.gmInit = () => {
  gmQueue.run();
  // do not initialize twice
  window.gmInit = () => {};
};

export default gmQueue;
